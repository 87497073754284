/*
****************************************
* APP
****************************************
*/

.btn-group {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5%;
  margin-top: 5%;
}

.title {
  margin-top: 5%;
  text-align: center;
}

.input-file,
.downloadLink {
  display: none;
}

/*
****************************************
* INPUT MODAL
****************************************
*/

.saveBtn {
  margin-top: 10px;
}
